import { styled } from 'styled-components';

const StyledUserSelectOption = styled.div`
  .option-row {
    display: flex;
    line-height: 18px;
    font-size: 14px;

    &--info {
      display: flex;
      justify-content: space-between;
      margin-bottom: 2px;
    }
    
    &--subinfo {
      color: ${props => props.theme.colorGrayDark };
    }
  }
`;

export default StyledUserSelectOption;