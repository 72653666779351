import React from 'react';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { push } from '@lagunovsky/redux-react-router';
import { useLocation, Link } from 'react-router-dom';
import { useTheme } from 'styled-components';
import { useDispatch } from 'react-redux';
import { useQuery } from '@apollo/client';
import PropTypes from 'prop-types';

import LoadingGfx from '../../../../shared/components/common/Loading/Loading.component';
import VerifiseringBanner from '../VerifiseringBanner/VerifiseringBanner.component';
import { logout } from '../../../../shared/components/Login/Login.actions';
import { toggleLoginModal } from '../Login/LoginModal/LoginModal.actions';
import HamburgerIcon from '../../../assets/icons/hamburger-small';
import { Container } from '../../../../shared/components/Grid';
import DatePicker from '../DatePicker/DatePicker.component';
import DropDownIcon from '../../../assets/icons/drop-down';
import { useStoreState } from '../../../../shared/hooks';
import Logo from '../../../../shared/assets/logo.default';
import { useQueryResponse } from '../../../hooks';
import { social } from '../../../config/header';

import { toggleHeaderMenu, toggleHeaderVisibility } from './Header.actions';
import HeaderMenuMobile from './HeaderMenuMobile/HeaderMenuMobile.component';
import HeaderLocation from './HeaderLocation/HeaderLocation.component';
import HeaderBasket from './HeaderBasket/HeaderBasket.container';
import StyledProfileBtn from './ProfileBtn/ProfileBtn.styled';
import HeaderMenu from './HeaderMenu/HeaderMenu.component';
import StyledLoginBtn from './LoginBtn/LoginBtn.styled';
import HeaderBtn from './HeaderBtn/HeaderBtn.component';
import { FETCH_MENU_LINKS } from './Header.queries';
import { createLinkProps } from './Header.helpers';
import BypassLink from './BypassLink/BypassLink';
import StyledHeader from './Header.styled';

const Header = ({ headerFixedLimit = 60, headerScrollLimit = 100 }) => {  
  const queryData = useQuery(FETCH_MENU_LINKS);
  const queryResponse = useQueryResponse(queryData);
  const dispatch = useDispatch();
  const location = useLocation();
  const theme = useTheme();
  const intl = useIntl();
  const { 
    route, 
    router, 
    user, 
    login: { loggedIn }, 
    header: {
      visibleLocationSelector: showLocationSelector,
      visibleDatePicker: showDatePicker,
      visibleBasket: showBasket,
      open: menuOpen,
      forceMobileView,
      visibleHeader,
    }
  } = useStoreState();

  const [ entries, setEntries ] = useState([]);
  const [ animated, setAnimated ] = useState(false);
  const [ headerFixed, setHeaderFixed ] = useState(false);
  const [ userItemActive, setUserItemActive ] = useState(false);
  const [ transparent, setTransparent ] = useState(false);

  const scrollAnchorRef = useRef();
  const currentScrollAmountRef = useRef(0);
  const lastScrollTopRef = useRef(0);
  const lastScrollDirectionUpRef = useRef(false);

  const links = useMemo(
    () => createLinkProps(entries || [], intl, loggedIn),
    [ loggedIn, intl, entries ]
  );

  const hideVerifyBanner = useMemo(() => {
    const pathname = router.location.pathname;
    const verifyPath = intl.formatMessage({ id: 'verifisering.route.path' });

    return pathname.includes(verifyPath);
  }, [ router, intl]);

  const clickLogout = useCallback(() => {
    dispatch(logout());
  }, [ dispatch ]);

  const toggleMenu = useCallback(() => {
    dispatch(toggleHeaderMenu());
  }, [ dispatch ]);

  const toggleLogin = useCallback(() => {
    dispatch(toggleLoginModal());
  }, [ dispatch ]);

  const navigateToRoute = useCallback((routePath) => {
    dispatch(push(routePath));
  }, [ dispatch ]);

  const handleHeaderVisibility = useCallback((visible) => {
    dispatch(toggleHeaderVisibility(visible));
  }, [ dispatch ]);

  const verifyUser = useCallback(() => {
    dispatch(push(`${intl.formatMessage({ id: 'verifisering.typeVerify.path' })}`));
  }, [ intl, dispatch ]);

  const checkScroll = useCallback((e) => {
    if (menuOpen && e) {
      e.preventDefault();
      e.returnValue = false;
    }

    const scrollTop = (
      window.pageYOffset 
      || document.documentElement.scrollTop 
      || document.body.scrollTop 
      || 0
    );
    const scrollDirectionUp = scrollTop < lastScrollTopRef.current;

    currentScrollAmountRef.current = scrollDirectionUp === lastScrollDirectionUpRef.current
      ? currentScrollAmountRef.current + lastScrollTopRef.current - scrollTop
      : 0;

    if (
      scrollTop === 0 
      || (scrollDirectionUp && scrollTop <= 0)
    ) {
      handleHeaderVisibility(true);

      setTransparent(route.config.transparentTop);
      setHeaderFixed(false);
      setAnimated(false);

    } else if (
      scrollDirectionUp 
      && currentScrollAmountRef.current > headerScrollLimit 
      && scrollTop > 0
    ) {
      handleHeaderVisibility(true);

      setHeaderFixed(true);
      setAnimated(true);

    } else if (
      !scrollDirectionUp 
      && scrollTop < headerFixedLimit
    ) {
      setHeaderFixed(false);
      setAnimated(false);

    } else if (
      !scrollDirectionUp 
      && Math.abs(currentScrollAmountRef.current) > headerScrollLimit
    ) {
      handleHeaderVisibility(false);

      setHeaderFixed(true);
    }

    lastScrollDirectionUpRef.current = scrollDirectionUp;
    lastScrollTopRef.current = scrollTop;
  }, [ 
    menuOpen, 
    route?.config, 
    headerFixedLimit, 
    headerScrollLimit, 
    handleHeaderVisibility
  ]);

  const setUserItemActiveTrue = useCallback(() => {
    setUserItemActive(true);
  }, []);

  const setUserItemActiveFalse = useCallback(() => {
    setUserItemActive(false);
  }, []);

  const subMenuClickHandler = useCallback((e) => {
    if (!e.currentTarget) {
      return;
    }

    setUserItemActive(false);

    navigateToRoute(intl.formatMessage({ 
      id: `${e.currentTarget.getAttribute('data-intlroute')}` 
    }));
  }, [ intl, navigateToRoute ]);

  const userLogout = useCallback(() => {
    setUserItemActive(false);

    setTimeout(() => {
      clickLogout();
    });
  }, [ clickLogout ]);

  const findNextFocusableElement = useCallback((element) => {
    const focusableElements = document.querySelectorAll(
      'input, select, textarea, button, a[href], [tabindex], [data-focusable]'
    );
    const focusableElementsList = Array
      .from(focusableElements)
      .filter(el => el.tabIndex >= 0);
    const currentFocusIndex = focusableElementsList.indexOf(element);

    let nextFocusableElement = focusableElementsList[currentFocusIndex + 1];

    if (!nextFocusableElement) {
      nextFocusableElement = focusableElementsList[0];
    }

    return nextFocusableElement;
  }, []);

  const scrollToContent = useCallback(() => {
    const section = document.querySelectorAll('section')[0];

    let sectionTabIndex;
    let nextFocusableElement;

    if (section) {
      nextFocusableElement = section;
      sectionTabIndex = section.tabIndex;
      section.tabIndex = 0;

    } else {
      nextFocusableElement = findNextFocusableElement(scrollAnchorRef.current);
    }

    nextFocusableElement.focus();
    nextFocusableElement.scrollIntoView({ behavior: 'smooth' });

    if (typeof sectionTabIndex === 'number') {
      section.tabIndex = sectionTabIndex;
    }
  }, [ findNextFocusableElement ]);

  useEffect(() => {
    window.addEventListener('scroll', checkScroll);
    document.body.addEventListener('touchmove', checkScroll);

    return () => {
      window.removeEventListener('scroll', checkScroll);
      document.body.removeEventListener('touchmove', checkScroll);  
    }
  }, [ checkScroll, location.pathname ]);

  useEffect(() => {
    if (!route?.config) {
      return;
    }

    setTransparent(route.config.transparentTop);
  }, [ route?.config ]);

  useEffect(() => {
    if (queryResponse.data) {
      setEntries(queryResponse.data.entries);
    }
  }, [ queryResponse.data ]);

  if (queryResponse.render) {
    return queryResponse.render();
  }

  const profileVerified = user.profile.isPersonVerified;
  const contactsVerified = user.profile.emailVerified && user.profile.mobileVerified;
  const verifyBannerRules = user.profile.id && (!profileVerified || !contactsVerified);
  const showVerifyBanner = !hideVerifyBanner && verifyBannerRules;
  const onlyContactInfo = profileVerified === true;

  return (
    <header>
      <BypassLink onClick={scrollToContent}>
        <FormattedMessage id="header.bypass" />
      </BypassLink>

      {showVerifyBanner && (
        <VerifiseringBanner
          verifyClick={verifyUser}
          onlyContactInfo={onlyContactInfo}
        />
      )}
      
      <StyledHeader
        $showVerifyBanner={showVerifyBanner}
        $forseMobile={forceMobileView}
        $white={!transparent}
        $shadow={forceMobileView}
        $fixed={forceMobileView && headerFixed}
        $visible={visibleHeader}
        $animated={animated}
      >
        <Container className="header__container" $full>
          <div className="hamburger-forceMobile-container">
            <HeaderBtn
              onClick={toggleMenu}
              visible={forceMobileView}
              aria-label="Menu"
            >
              <HamburgerIcon
                alt={intl.formatMessage({ id: 'header.menu' })}
                fill={!transparent ? theme.colorGrayDarker : null}
              />
            </HeaderBtn>

            {forceMobileView && (
              <Link
                to={'/'}
                className="logo-equipment"
                role="navigation"
                aria-label="Main"
              >
                <Logo
                  alt={intl.formatMessage({ id: 'header.logo' })}
                  className="logo"
                  fill={!transparent ? theme.colorGrayDarker : null}
                />
              </Link>
            )}
          </div>

          {forceMobileView ? (
            null 

          ) : (
            <>
              <Link to={'/'} role="navigation" aria-label="Main">
                <Logo
                  className="logo"
                  alt={intl.formatMessage({ id: 'header.logo' })}
                  fill={!transparent ? theme.colorGrayDarker : null}
                />
              </Link>

              <HeaderMenu transparent={transparent} links={links} />
            </>
          )}

          <div className="header__middle-content">
            {showLocationSelector && (
              <HeaderLocation />
            )}
            
            {showDatePicker && (
              <DatePicker inHeader enableOnClick />
            )}
          </div>

          <div className="header__login-content">
            {loggedIn ? (
              <StyledProfileBtn
                $transparent={transparent}
                className={userItemActive && 'active'}
                onMouseOver={setUserItemActiveTrue}
                onMouseLeave={setUserItemActiveFalse}
              >
                <div>
                  {user.loading && !user.profile.id ? (
                    <LoadingGfx fill={theme.colorGrayLight} fadeStyle />

                  ) : (
                    user.profile.firstName
                  )}

                  <DropDownIcon fill={!transparent ? theme.colorGrayDarker : null} />
                </div>

                <ul
                  onMouseOver={setUserItemActiveTrue}
                  onMouseLeave={setUserItemActiveFalse}
                >
                  <li
                    onClick={subMenuClickHandler}
                    data-intlroute={'profile.route.path'}
                  >
                    <FormattedMessage id="profile.route.name" />
                  </li>

                  <li
                    onClick={subMenuClickHandler}
                    data-intlroute={'archive.route.path'}
                  >
                    <FormattedMessage id="archive.route.name" />
                  </li>

                  <li onClick={userLogout}>
                    <FormattedMessage id="forms.logoutCta" />
                  </li>
                </ul>
              </StyledProfileBtn>

            ) : (
              location.pathname === intl.formatMessage({ id: 'login.route.path' }) 
              || location.pathname === intl.formatMessage({ id: 'register.route.path' })
            ) ? (
              null 

            ) : (
              <StyledLoginBtn
                $transparent={transparent}
                onClick={toggleLogin}
              >
                {user.loading ? (
                  <LoadingGfx fill={theme.colorPurple} fadeStyle />

                ) : (
                  <React.Fragment>
                    <span className="login-link-text__short">
                      <FormattedMessage id="forms.loginCta" />
                    </span>
                    
                    <span className="login-link-text__long">
                      <FormattedMessage id="forms.loginRegisterCta" />
                    </span>
                  </React.Fragment>
                )}
              </StyledLoginBtn>
            )}
            {showBasket && <HeaderBasket light={transparent} />}
          </div>
        </Container>
      </StyledHeader>

      <HeaderMenuMobile
        showCloseButton={forceMobileView}
        open={menuOpen}
        toggleMenu={toggleMenu}
        links={links}
        loggedIn={loggedIn}
        social={social}
        clickLogout={clickLogout}
      />

      <a ref={scrollAnchorRef} hidden data-focusable>
        Scroll anchor
      </a>
    </header>
  );
};

Header.propTypes = {
  headerFixedLimit: PropTypes.number,
  headerScrollLimit: PropTypes.number,
};

export default Header;
